import styled from "styled-components";
import Loader from "assets/puff.svg";

interface FadeProps {
    visible?: boolean;
}
export const PreloaderContainer = styled.div<FadeProps>`
    background : #ccc;
    bottom     : 0;
    left       : 0;
    position   : fixed;
    right      : 0;
    top        : 0;
    z-index    : 9999;

    ${(props) =>
        !props.visible &&
        `
        z-index: -1;
        opacity: 0;
        transition: opacity 1s ease;
      `}
`;

export const PreloaderBody = styled.div`
    background-image    : url(${Loader});
    background-position : center;
    background-repeat   : no-repeat;
    height              : 200px;
    left                : 50%;
    margin              : -100px 0 0 -100px;
    position            : absolute;
    top                 : 50%;
    width               : 200px;

`;
