import styled from "styled-components";

export const NameWrapper = styled.div`
    h1 {
        text-transform: uppercase;
        line-height: 1;
        margin: 0 0 5px;
    }
    small {
        font-size: 24px;
    }
`;

export const PersonalDetails = styled.div`
    margin: 10px 0;
    title {
        display: block;
        line-height: 1;
        font-weight: bold;
    }
    small {
        color: #7df9ff
    }
    span {
        color: #989898;
    }
`;

export const HeaderStyle = styled.header`
    padding: 50px 0;
    margin: 0;
    position: relative;
    @media (max-width: 768px) {
        padding: 20px 0;
    }
    @media (max-width: 600px) {
        padding: 20px;
    }

`;

export const CurriculumVitae = styled.div`
    margin-bottom: 30px;
	display: block;
    a {
        color: #1769aa;
        &:hover {
            color: #444444
        }
    }
    i {
        &::before {
            margin-right: 5px
        }
        margin-right: 20px
    }
`;