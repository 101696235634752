import React from 'react';
import { WhatsApp } from './styled'

function isMobile(): boolean {
    if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        return true
    } else {
        return false
    }
}

export interface Props{
    phone: string
}

export const WhatsappComponent: React.FunctionComponent<Props> = (props) => {

    const mobile: boolean = isMobile();
    
    return (
        <>
        {mobile ? (
            <WhatsApp href={"whatsapp://send?phone=" + props.phone } target="_blank">
                <i className="fa fa-whatsapp"></i>
            </WhatsApp>
        ) : (
            <WhatsApp href={"https://web.whatsapp.com/send?phone=" + props.phone } target="_blank">
                <i className="fa fa-whatsapp"></i>
            </WhatsApp>
        )}
        </>
    );
}