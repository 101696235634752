import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { SectionWrapper, SectionTitle, PortfolioItem, PortfolioThumb, PortfolioInfo } from './styled'

export interface Props {
    title: string,
    items: Array<any>

}

export const ProyectsComponent: React.FunctionComponent<Props> = (props) => {
   
    return (
        <>
        <SectionWrapper>
            <Container fluid>
                <Row>
                    <Col md={3}>
                        <SectionTitle>
                            <h2>{ props.title }</h2>
                        </SectionTitle>
                    </Col>
                    <Col md={9}>
                        <Row>
                        {props.items.map(function(name, index){
                            return (
                                <>
                                <Col md={4}>
                                    <PortfolioItem href={name.url} target="_blank">
                                            <PortfolioThumb>
                                                <img src={name.img} alt="item-proyect" />
                                            </PortfolioThumb>
                                            <PortfolioInfo className="information">
                                                <h3>{ name.role }</h3>
                                            </PortfolioInfo>
                                    </PortfolioItem>
                                </Col>
                                </>
                        )})}
                        </Row>
                    </Col>
                </Row>
            </Container>
        </SectionWrapper>
        </>
    );
}