import styled from 'styled-components';

export const SectionTimeline = styled.div`
    .vertical-timeline--line::before {
        background: #444444!important
    }
`;

export const SectionTitle = styled.div`
    margin-right : 30px;
    text-align   : right;
    h2 {
        font-size      : 14px;
        color          : #858181;
        margin-bottom  : 5px;
        text-transform : uppercase;
    }

    @media (max-width: 768px) {
        text-align: left;
        h2 {
            margin-bottom: 20px;
        }
    }
`;