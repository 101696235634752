import styled from 'styled-components'

export const ProfileImage = styled.div`
    margin-right: 30px;
    margin: 0 0 30px;

    img {
        display: block;
        max-width: 100%;
        height: auto;
        float: right;
        width: 20em;
    }
`;