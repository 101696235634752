import React, { useEffect } from 'react';
import { PreloaderContainer, PreloaderBody } from './styled'

export const PreloaderComponent: React.FunctionComponent<{}> = (props) => {
    const [fadeProp, setFadeProp] = React.useState(true);

    useEffect(() => {
        setInterval(() => {
            setFadeProp(false);
        }, 1000)
    })
    //let refDropDown: HTMLInputElement | null = null;
    return (
        <>
            <PreloaderContainer visible={fadeProp}>
                <PreloaderBody />
            </PreloaderContainer>
        </>
    );
}