import iconAws from 'assets/skills/AWS.svg'
import iconDocker from 'assets/skills/Docker.svg'
import iconHtml from 'assets/skills/HTML.svg'
import iconJava from 'assets/skills/Java.svg'
import iconLinux from 'assets/skills/Linux.png'
import iconMongoDB from 'assets/skills/MongoDB.svg'
import iconMysql from 'assets/skills/MySQL.svg'
import iconNode from 'assets/skills/nodeJS.svg'
import iconPHP from 'assets/skills/PHP.svg'
import iconPython from 'assets/skills/Python.svg'
import iconReact from 'assets/skills/React.svg'
import iconAngular from 'assets/skills/Angular.svg'
import iconAndroid from 'assets/skills/Android.svg'
import iconJenkins from 'assets/skills/Jenkins.svg'
import iconKotlin from 'assets/skills/Kotlin.svg'
import iconGcp from 'assets/skills/Gcp.svg'
import logoCulqi from 'assets/proyects/logo-culqi.png'
import logoNeo from 'assets/proyects/logo-neoauto.svg'
import logoLumingo from 'assets/proyects/logo-lumingo.png'
import logoWabi from 'assets/proyects/logo-wabi.png'

export const data = {
    "name": "CESAR ANDRES CASANOVA BUSTAMANTE",
    "title": "Backend Developer / DevOps",
    "description": "Soy un desarrollador backend altamente cualificado y experimentado con 10 años de experiencia en PHP, Python y Java. Tengo una fuerte pasión por la construcción de soluciones eficientes y escalables. Con 5 años de experiencia en el despliegue de soluciones en Amazon Web Services, automatizado con CD/CI pipelines, creo que sería un activo valioso para su equipo. Estoy ansioso por contribuir con mi experiencia y ayudar a impulsar el éxito de la infraestructura en la nube de su empresa.",
    "imgProfile": "/assets/devccasanova.jpg",
    "contactData": {
        "title": "Contacto",
        "mobile": "+51949378166",
        "list": [
            {"id": 0, "value": "+51 949 378 166", "title": "Teléfono"},
            {"id": 1, "value": "cesar.casanovab92@gmail.com", "title": "Email"}
        ]
    },
    "cv": "/certificates/cv.pdf",
    "networks": [
        {"id": 0, "clss": "fa fa-linkedin", "url": "https://www.linkedin.com/in/ccasanovab"}
    ],
    "personalDetail": [
        {"id": 0, "detail":"Nacimiento", "value": "ABRIL 23, 1992"},
        {"id": 1, "detail":"Nacionalidad", "value": "PERUANO"},
        {"id": 2, "detail":"Idioma", "value": "ESPAÑOL"},
        {"id": 3, "detail":"Idioma", "value": "INGLÉS B2"}
    ],
    "specialities": {
        "title": "Especialidades",
        "list": [
            {"id": 0, "title": "Arquitectura de Software", "value": "Diseño de software de alta disponibilidad con soluciones en la nube."},
            {"id": 1, "title": "Desarrollo de Software", "value": "Desarrollo de software a medida, lo que me permite garantizar un software mantenible y óptimo."}
        ]
    },
    "skill": {
        "title": "Tecnologías",
        "list": [
            {
                "id": 'backend',
                "title": "Backend",
                "list": [
                    {"id": 'backend-php', "title": "PHP", "icon": iconPHP},
                    {"id": 'backend-python', "title": "Python", "icon": iconPython},
                    {"id": 'backend-java', "title": "Java", "icon": iconJava},
                    {"id": 'backend-android', "title": "Android", "icon": iconAndroid},
                    {"id": 'backend-node', "title": "NodeJS", "icon": iconNode},
                    {"id": 'backend-kotlin', "title": "Kotlin", "icon": iconKotlin}
                ]
            },
            {
                "id": 'frontend',
                "title": "Frontend",
                "list": [
                    {"id": 'frontend-react', "title": "React", "icon": iconReact},
                    {"id": 'frontend-angular', "title": "Angular", "icon": iconAngular},
                    {"id": 'frontend-html5', "title": "Html5", "icon": iconHtml}
                ]
            },
            {
                "id": 'cloud',
                "title": "Servicios Cloud",
                "list": [
                    {"id": 'cloud-aws', "title": "AWS", "icon": iconAws},
                    {"id": 'cloud-gcp', "title": "GCP", "icon": iconGcp},
                ]
            },
            {
                "id": 'sysadmin',
                "title": "SysAdmin",
                "list": [
                    {"id": 'sysadmin-docker', "title": "Docker", "icon": iconDocker},
                    {"id": 'sysadmin-linux', "title": "Linux", "icon": iconLinux},
                    {"id": 'sysadmin-jenkins', "title": "Jenkins", "icon": iconJenkins},
                    {"id": 'sysadmin-mysql', "title": "Mysql", "icon": iconMysql},
                    {"id": 'sysadmin-mongodb', "title": "MongoDB", "icon": iconMongoDB}
                ]
            }
        ]
    },
    "workExperience": {
        "title": "Experiencia Laboral",
        "list": [
            {
                "id": 0,
                "date": "Actual",
                "role": "DevOps Engineer",
                "description": "OutCode Software",
                "location": "Lima, Perú / Utah, USA"
            },
            {
                "id": 1,
                "date": "2023",
                "role": "Ingeniero de Software / DevOps AWS",
                "description": "SES Digital",
                "location": "Lima, Perú"
            },
            {
                "id": 2,
                "date": "2023",
                "role": "BackEnd Developer",
                "description": "Culqi",
                "location": "Lima, Perú"
            },
            {
                "id": 3,
                "date": "2022-2023",
                "role": "Backend Cloud Developer",
                "description": "Wabi Project",
                "location": "Buenos Aires, Argentina (Remoto)"
            },
            {
                "id": 4,
                "date": "2019 - 2021",
                "role": "Backend Developer / DevOps Engineer",
                "description": "Grupo el Comercio - Lumingo MarketPlace",
                "location": "Lima, Perú"
            },
            {
                "id": 5,
                "date": "2015 - 2019",
                "role": "Backend Developer / DevOps Engineer",
                "description": "Grupo el Comercio - NeoAuto",
                "location": "Lima, Perú"
            },
            {
                "id": 6,
                "date": "2014 - 2015",
                "role": "Backend Developer",
                "description": "Media Markenting Lab (Medialab).",
                "location": "Lima, Perú"
            },
            {
                "id": 7,
                "date": "2014",
                "role": "Fullstack Developer",
                "description": "Anytime",
                "location": "Lambayeque, Perú"
            },
            {
                "id": 8,
                "date": "2012 - 2014",
                "role": "Fullstack Developer (Freelance)",
                "description": "Trasvase Olmos - Presa Limón",
                "location": "Lambayeque, Perú"
            }
        ]
    },
    "education": {
        "title": "Estudios",
        "list": [
            {
                "id": 0,
                "date": "2020",
                "role": "Certificado: Scrum Fundations",
                "cert": "/certificates/CertificateScrum.pdf",
                "description": "CertiProf",
                "location": "Online"
            },
            {
                "id": 1,
                "date": "2008 - 2014",
                "role": "Ingenieria de Sistemas",
                "cert": "/certificates/Titulo.pdf",
                "description": "Universidad Nacional Pedro Ruiz Gallo - UNPRG",
                "location": "Lambayeque, Perú"
            }
        ]
    },
    "portfolio": {
        "title": "Proyectos",
        "list": [
            {
                "id": 0,
                "url": "https://wabi2b.com",
                "img": logoWabi,
                "role": "Backend Java Developer",
                "enterprise": "wabi2b.com"
            },
            {
                "id": 1,
                "url": "https://lumingo.com",
                "img": logoLumingo,
                "role": "AWS Developer",
                "enterprise": "lumingo.com"
            },
            {
                "id": 2,
                "url": "https://neoauto.com",
                "img": logoNeo,
                "role": "AWS Developer / DevOps",
                "enterprise": "neoauto.com"
            },
            {
                "id": 3,
                "url": "https://culqi.com",
                "img": logoCulqi,
                "role": "Backend Node Developer / DevOps",
                "enterprise": "culqi.com"
            }
        ]
    }
}