import React from 'react';
import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';
import { IconEducation } from 'components/Icons';
import { Col, Container, Row } from 'react-bootstrap';
import { SectionTimeline, SectionTitle } from './styled';

export interface Props {
    title: String,
    items: Array<any>
}

export const TimelineEducationComponent: React.FunctionComponent<Props> = (props) => {
    return (
        <>
        <Container fluid>
            <Row>
                <Col md={3}>
                    <SectionTitle>
                        <h2>{ props.title }</h2>
                    </SectionTitle>
                </Col>
                <Col md={9}>
                    <SectionTimeline>
                        <VerticalTimeline 
                            className="vertical-timeline--line"
                            layout="1-column">
                            {props.items.map(function(name, index){
                                return (
                                    <>
                                        <VerticalTimelineElement
                                            className="vertical-timeline-element--education"
                                            contentStyle={{ background: '#ffffff', border: '1px solid #444444' }}
                                            contentArrowStyle={{ borderRight: '7px solid #353535' }}
                                            iconStyle={{ background: '#e91e63', color: '#ffffff' }}
                                            icon={ 
                                                <IconEducation 
                                                    width={24}
                                                    height={24}
                                                    fill="#ffffff"
                                                />
                                            }
                                            date={ name.date }
                                        >
                                            <h3 className="vertical-timeline-element-title">
                                                { name.role }&nbsp;
                                                { name.cert && 
                                                    <a href={ name.cert } target="_blank" rel="noopener noreferrer">
                                                        <i className="fa fa-external-link"></i>
                                                    </a>
                                                }
                                                
                                            </h3>
                                            <h4 className="vertical-timeline-element-subtitle">{ name.location }</h4>
                                            <p>{ name.description }</p>
                                        </VerticalTimelineElement>
                                    </>
                                );
                            })}
                        </VerticalTimeline>
                    </SectionTimeline>
                    
                </Col>
            </Row>
        </Container>
        </>
    )
}