import styled from 'styled-components';

export const SectionWrapper = styled.div`
    padding : 35px 0;

    @media (max-width : 768px) {
        padding : 20px 0;
    }

    @media (max-width : 600px){
        padding : 20px;
    }
`;

export const SectionTitle = styled.div`
    margin-right : 30px;
    text-align   : right;
    h2 {
        font-size      : 14px;
        color          : #858181;
        margin-bottom  : 5px;
        text-transform : uppercase;
    }

    @media (max-width: 768px) {
        text-align: left;
        h2 {
            font-size: 14px;
            margin-bottom: 20px;
        }
    }
`;

export const SectionStat = styled.div`
    min-height: 100%;
    margin-bottom : 10px;
    border-style: solid;
    border-width: 0px 0px 0px 0px;
    padding-left: 5px;
    .item-stats-title {
        text-transform: uppercase;
        font-size: 13px;
        font-weight: bold;
        margin-bottom : 10px;
    }
    .item-stats-value {
        line-height: 1;
    }
`;

export const SectionCaption = styled.figure`
    text-align: center;
    display: inline-block;
    img {
        max-width: 100%;
    }
    figcaption {
        font-size: .875em;
    }
`;