import React from 'react'
import { UserComponent } from 'components/User'
import { PreloaderComponent } from 'components/Preloader'
export const Home: React.FunctionComponent<{}> = () => {
    window.history.pushState(undefined, "", "/");
    return (
        <>
            <PreloaderComponent />
            <UserComponent />
        </>
    )
}