import styled from "styled-components";

interface IconSvgProps {
  fill?: string;
  stroke?: string;
}

export const IconSvg = styled.svg<IconSvgProps>`
  & * {
    fill: ${(props) => props.fill} !important;
    stroke: ${(props) => props.stroke || props.fill} !important;
    stroke-width: 0 !important;
  }
`;

export const IconSvgNotFill = styled.svg<IconSvgProps>`
  & * {
    stroke-width: 0 !important;
  }
`;
