import React from 'react';
import { data } from '../../data';
import { HeaderStyle, NameWrapper, PersonalDetails, CurriculumVitae } from './styled'
import { ProfileImageComponent } from 'components/ProfileImage'
import { SocialIconsComponent } from 'components/SocialIcons'
import { SectionWrapperComponent } from 'components/SectionWrapper'
import { TechComponent } from 'components/Tech'
import { TimelineWorkComponent } from 'components/TimelineWork'
import { TimelineEducationComponent } from 'components/TimelineEducation'
import { ProyectsComponent } from 'components/Proyects'
import { WhatsappComponent } from 'components/WhatsApp'

import { Col, Row } from 'react-bootstrap';

export const UserComponent: React.FunctionComponent<{}> = (props) => {
    //const [showUserZone, setShowUserZone] = React.useState(false);
    //let refDropDown: HTMLInputElement | null = null;

    return (
        <>
            <HeaderStyle>
                <Row>
                    <Col md={3}>
                        <ProfileImageComponent />
                    </Col>
                    <Col md={9}>
                        <NameWrapper>
                            <h1 className="name">{ data.name }</h1>
                            <small>{ data.title }</small>
                        </NameWrapper>
                        <CurriculumVitae>
                            <a href={data.cv} target="_blank" rel="noreferrer">
                                <i className="fa fa-file-pdf-o">Descargar CV</i>
                            </a>
                        </CurriculumVitae>
                        <p>
                            { data.description }
                        </p>
                        <Row>
                        {data.personalDetail.map(function(name, index){
                            return (
                                <>
                                    <Col md={3}>
                                        <PersonalDetails>
                                            <title>{ name.detail }</title>
                                            <small>{ name.value }</small>
                                        </PersonalDetails>
                                    </Col>
                                </>
                            );
                        })}
                        </Row>
                        <SocialIconsComponent items={data.networks} />
                    </Col>
                </Row>
            </HeaderStyle>
            <SectionWrapperComponent title={data.specialities.title} items= {data.specialities.list}/>
            <TechComponent title={data.skill.title} items= {data.skill.list}/>
            <TimelineWorkComponent 
                title={data.workExperience.title} 
                items= {data.workExperience.list} />
            <TimelineEducationComponent 
                title={data.education.title} 
                items= {data.education.list} />
            
            <ProyectsComponent 
                title={data.portfolio.title}
                items= {data.portfolio.list} />
            
            <SectionWrapperComponent title={data.contactData.title} items= {data.contactData.list}/>
            <WhatsappComponent phone={data.contactData.mobile} />
        </>
    );
}