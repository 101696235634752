import styled from 'styled-components';

export const PortfolioItem = styled.a`
	position: relative;
	display: block;
	margin-bottom: 30px;
	
	&::after {
		content: "";
		position: absolute;
		bottom: 0;
		top: 100%;
		left: 0;
		right: 0;
		background: -webkit-linear-gradient(
            top,
            transparent 0%,
            #000 100%
        );
		transition: all .2s linear;
		background: transparent;
	}
	&:hover > .information {
        display: block;
        transition: background 0.2s ease;
        background: #f8f8f8;
	}

`;

export const PortfolioThumb = styled.div`
    img {
        height: 35px;
        display: block;
        max-width: 100%;
        background-color: #fff0;
        border: 1px solid #ddd0;
        margin: 0 auto;
    }
`;

export const PortfolioInfo = styled.div`
    position: absolute;
    padding: 15px 15px 5px;
    bottom: 0;
    width: 100%;
    z-index: 1;
    background: -webkit-linear-gradient(
        top,
        transparent 0%,
        rgba(0, 0, 0, 0.5) 100%
    );
    text-align: center;
    h3 {
        margin: 0;
        line-height: 1;
        color: #ffffff;
        font-weight: bold;
        font-size: 13px;
        text-transform: uppercase;
    }
    small {
        color: rgba(255, 255, 255, .5);
    }
`;

export const SectionWrapper = styled.div`
    padding : 35px 0;

    @media (max-width : 768px) {
        padding : 20px 0;
    }

    @media (max-width : 600px){
        padding : 20px;
    }
`;


export const SectionTitle = styled.div`
    margin-right : 30px;
    text-align   : right;
    h2 {
        font-size      : 14px;
        color          : #858181;
        margin-bottom  : 5px;
        text-transform : uppercase;
    }

    @media (max-width: 768px) {
        text-align: left;
        h2 {
            margin-bottom: 20px;
        }
    }
`;