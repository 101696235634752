import React from "react";
import { IconSvg } from "./styled";

export interface IconProps {
  width: number;
  height: number;
  fill?: string;
  viewBox?: string;
}

export const IconWork: React.FC<IconProps> = ({ width, height, fill }) => (
  <IconSvg
    width={width}
    height={height}
    fill={fill}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path 
      d="M20 6h-4V4c0-1.11-.89-2-2-2h-4c-1.11 0-2 .89-2 2v2H4c-1.11 0-1.99.89-1.99 2L2 19c0 1.11.89 2 2 2h16c1.11 0 2-.89 2-2V8c0-1.11-.89-2-2-2zm-6 0h-4V4h4v2z"
      fill="#58595B"
    />
  </IconSvg>
  );
  
export const IconEducation: React.FC<IconProps> = ({ width, height, fill }) => (
  <IconSvg
    width={width}
    height={height}
    fill={fill}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path 
      d="M5 13.18v4L12 21l7-3.82v-4L12 17l-7-3.82zM12 3L1 9l11 6 9-4.91V17h2V9L12 3z">
    </path>
  </IconSvg>
  );
