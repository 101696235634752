import React from 'react';
import { SocialIcons } from './styled';

export interface Props {
    items: Array<any>;
}

export const SocialIconsComponent : React.FunctionComponent<Props> = (props) => {
    return (
        <SocialIcons>
            {props.items.map(function(name, index){
                return <li>
                    <a href={name.url} target="_blank" rel="noreferrer">
                        <i className={name.clss} aria-hidden="true"></i>
                    </a>
                </li>
            })}
        </SocialIcons>
    )
}