import React from 'react';
import { ProfileImage } from './styled'
import profileImg from 'assets/photo.jpeg'

export const ProfileImageComponent: React.FunctionComponent<{}> = (props) => {
    return (
        <>
            <ProfileImage>
                <img src={ profileImg } alt="profileImg" />
            </ProfileImage>
        </>
    )
}