import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { SectionWrapper, SectionTitle, SectionStat, SectionCaption } from './styled'

export interface Props {
    title: String,
    items: Array<any>
}

export const SectionWrapperComponent: React.FunctionComponent<Props> = (props) => {
    return (
        <>
            <SectionWrapper>
                <Container fluid>
                    <Row>
                        <Col md={3}>
                            <SectionTitle>
                                <h2>{ props.title }</h2>
                            </SectionTitle>
                        </Col>
                        <Col md={9}>
                            <Row>
                            {props.items.map(function(name, index){
                                return (
                                    <>
                                        <Col>
                                            
                                            <SectionStat>
                                                <div className="item-stats-title">{ name.title }</div>
                                                { !name.list && 
                                                    <div className="item-stats-value">{ name.value }</div>
                                                }
                                                { name.list && 
                                                    <Row>
                                                    {name.list.map(function(name2: any, index2: any){
                                                        return (
                                                            <>
                                                                <Col>
                                                                    <SectionCaption>
                                                                        <img src={name2.icon} width="48" height="48" alt={"Icon"+name2.title}/>
                                                                        <figcaption>{name2.title}</figcaption>
                                                                    </SectionCaption>
                                                                </Col>
                                                            </>
                                                        )
                                                    })}
                                                    </Row>
                                                }
                                            </SectionStat>
                                        </Col>
                                    </>
                                )
                            })}
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </SectionWrapper>
        </>
    );
}